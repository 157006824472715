import { VEHICLE_EMISSIONS_ACRISS_ENDPOINT } from "../../../util/Constants"
import VisxChartWrapper from "../../ChartsVisX/shared/VisxChartWrapper"
import Donut from "../../ChartsVisX/ChartType/Donut/Donut"
import { palette } from "../../../theme"
import { getVehicleEmissionsByAcrissDataSet } from "../../../lib/convertions/VehicleDataSet"

const queryData = {
	url: VEHICLE_EMISSIONS_ACRISS_ENDPOINT,
}

const VehicleEmissionsByClass = () => {
	return (
		<VisxChartWrapper queryData={queryData} dataTransformFunction={(data) => getVehicleEmissionsByAcrissDataSet(data.data.roadEmissions)}>
			{({ transformedData, width }) => (
				<Donut
					{...{
						containerWidth: width,
						containerHeight: (6 * width) / 11,
						barColors: palette.charts.colorScale,
						title: "By type",
						subTitle: null,
						unit: "tCO2e",
						data: transformedData,
					}}
				/>
			)}
		</VisxChartWrapper>
	)
}
export default VehicleEmissionsByClass
