import { trimArrayOfKeyValueObjects } from "../../util/util"

export const getVehicleEmissionsByAcrissDataSet = (emissionData) => {
	if (!emissionData) return []

	const mappedData = emissionData.map((data) => {
		return {
			key: data.acrissCategory,
			value: data.emissions,
		}
	})

	return trimArrayOfKeyValueObjects(mappedData)
}

export const getVehicleEmissionsByKmDataSet = (emissionData) => {
	if (!emissionData) return []

	const mappedData = emissionData.map((data) => {
		return {
			key: data.kmTier,
			value: data.emissions,
		}
	})

	return trimArrayOfKeyValueObjects(mappedData)
}
